import React from 'react';
import Image from 'next/image';
import { MainContentAnchor } from '../../shared/accessibility';
import Head from 'next/head';
import { Heading } from '../atoms/typography/Header';

const sideNote = (
  <>
    <Heading variant='h1' stylesOf='h2' className='mb-7 text-white'>
      Froedtert & MCW health network
    </Heading>
    <div>
      <div>
        <span className='font-normal text-xl'>Did you know?</span>
        <br />
        <br />
        <span className='font-normal text-xl'>
          Your new Froedtert & MCW account will be your universal login to your care experiences. Use a single password
          to access the Froedtert & MCW mobile app, MyChart, video visits and more...
        </span>
      </div>
    </div>
  </>
);

const MainPageTemplate: React.FC<{ children?: React.ReactNode; headTitle?: string; hideLogo?: boolean }> = ({
  children,
  headTitle,
  hideLogo,
}) => {
  return (
    <>
      {headTitle && (
        <Head>
          <title>{headTitle}</title>
        </Head>
      )}
      <div className='flex flex-col-reverse lg:flex-row min-h-screen text-inception-primary-blue-100'>
        <div className='flex-grow lg:w-1/2 pt-12 px-8 lg:pt-16 lg:px-20  lg:min-h-screen flex flex-col lg:justify-between bg-inception-secondary-green-100 bg-& bg-left-bottom bg-contain bg-no-repeat text-white'>
          <div>{sideNote}</div>
          <div className='h-8 mb-8 mt-8 flex flex-row justify-center text-lg'>
            <a
              className='text-white mx-6 p-2'
              rel='noreferrer'
              target='_blank'
              href='https://help.my.froedtert.com/hc/en-us/articles/26756695335963-Terms-and-Conditions'>
              Terms and Conditions
            </a>{' '}
            <a
              className='text-white mx-6 p-2'
              rel='noreferrer'
              target='_blank'
              href='https://help.my.froedtert.com/hc/en-us/articles/26756721731227-Privacy-Information'>
              Privacy
            </a>
          </div>
        </div>

        <main
          id={MainContentAnchor}
          className='flex-grow lg:w-1/2 mx-6 mt-14 mb-5 md:mx-20 md:mb-12 lg:mx-32 lg:items-start'>
          {!hideLogo && (
            <div className='relative mb-16 max-w-sm min-w-0 w-full self-center lg:self-start'>
              <Image
                src='/assets/logos/fmcw-logo.jpg'
                alt='Froedtert & MCW logo'
                width={384}
                height={94}
                layout='responsive'
              />
            </div>
          )}
          {children}
        </main>
      </div>
    </>
  );
};

export default MainPageTemplate;
